import React from "react";

function InvitePeopleIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="45"
      height="44"
      fill="none"
      viewBox="0 0 45 44"
    >
      <path
        fill="#fff"
        d="M11.958 11.688a7.563 7.563 0 1115.126 0 7.563 7.563 0 01-15.126 0zm-5.5 23.374a13.062 13.062 0 1126.125 0v.006l-.002.218a1.375 1.375 0 01-.665 1.155 23.956 23.956 0 01-12.395 3.434 23.947 23.947 0 01-12.393-3.434 1.375 1.375 0 01-.668-1.155l-.002-.224zm30.25-21.312a1.375 1.375 0 10-2.75 0v4.125h-4.125a1.375 1.375 0 000 2.75h4.125v4.125a1.375 1.375 0 002.75 0v-4.125h4.125a1.375 1.375 0 000-2.75h-4.125V13.75z"
      ></path>
    </svg>
  );
}

export default InvitePeopleIcon;
